import styled from 'styled-components';

import { Loading } from 'components/Loading';
import { Menu } from 'components/Menu';
import { SearchBar } from 'components/SearchBar';
import { User } from 'components/User';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

const Wrapper = styled.main`
  width: 100%;
  min-height: calc(100vh - 48px);
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 2rem;
`;

const Footer = styled.footer`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 2rem;
`;

type MainProps = {
  children: ReactNode;
};

const Main = ({ children }: MainProps) => {
  const { data: session, status } = useSession();
  const loading = status === 'loading';
  const router = useRouter();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);

  const checkQuery = () => {
    const { q, f } = router.query;
    const word = Array.isArray(q) ? q[0] : q;
    setSearchOpen(!!word);
    setSearchQuery(word || '');

    if (f) {
      toast.error('Ops!! Redirect not found!!');
      toast(`Searching for you: ${word}`);
    }
  };

  useEffect(() => {
    if (!session && !loading) {
      router.push('/login');
    }
  }, [session, loading]);

  useEffect(() => {
    checkQuery();
  }, []);

  useEffect(() => {
    checkQuery();
  }, [router.isReady, router.query]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SearchBar
        value={searchQuery}
        open={searchOpen}
        onChange={setSearchQuery}
        onToggle={setSearchOpen}
      />
      <Wrapper>
        <Menu onToggleSearch={() => setSearchOpen(!searchOpen)} />
        <Content>{children}</Content>
        <User />
      </Wrapper>
      <Footer>Made with ❤ by AE Studio</Footer>
    </>
  );
};

export default Main;
