import { ULIcon } from 'components/ULIcon';
import Link from 'next/link';
import { BsArrowUpCircle } from 'react-icons/bs';
import { GiMountainRoad } from 'react-icons/gi';
import { HiHeart, HiHome, HiPlus, HiTag } from 'react-icons/hi';
import { MdSearch } from 'react-icons/md';
import styled from 'styled-components';

const Wrapper = styled.header`
  position: relative;
  width: 36px;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;

const Button = styled.button`
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: ${({ theme }) => theme.colors.white};
  transition: transform 0.3s ease-in-out;

  &:focus {
    outline: none;
  }

  &:hover {
    transform: scale(1.2) rotate(10deg);
  }
`;

const AquaButton = styled(Button)`
  color: ${({ theme }) => theme.colors.aqua};
`;

const CherryButton = styled(Button)`
  color: ${({ theme }) => theme.colors.cherry};
`;

const LemonButton = styled(Button)`
  color: ${({ theme }) => theme.colors.lemon};
`;

type MenuProps = {
  onToggleSearch: () => void;
};

export const Menu = ({ onToggleSearch }: MenuProps) => {
  return (
    <Wrapper>
      <Button type="button" onClick={() => onToggleSearch()}>
        <MdSearch size={28} />
      </Button>
      <Link href="/">
        <Button>
          <HiHome size={28} />
        </Button>
      </Link>
      <Link href="/add">
        <AquaButton>
          <HiPlus size={28} />
        </AquaButton>
      </Link>
      <Link href="/loved">
        <CherryButton>
          <HiHeart size={28} />
        </CherryButton>
      </Link>
      <Link href="/tags">
        <LemonButton>
          <HiTag size={28} />
        </LemonButton>
      </Link>
      <Link href="/roadmap">
        <Button>
          <GiMountainRoad size={28} />
        </Button>
      </Link>
      <Link href="http://universallauncher.com">
        <a target="_blank">
          <Button style={{ width: '24px' }}>
            <ULIcon />
          </Button>
        </a>
      </Link>
      <Link href="http://getgratz.com">
        <a target="_blank">
          <Button style={{ width: '24px' }}>
            <BsArrowUpCircle size={28} />
          </Button>
        </a>
      </Link>
    </Wrapper>
  );
};
