export const ULIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 39 37"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
    >
      <rect
        id="Artboard1"
        x="0"
        y="0"
        width="38.582"
        height="36.684"
        style={{ fill: 'none' }}
      />
      <clipPath id="_clip1">
        <rect x="0" y="0" width="38.582" height="36.684" />
      </clipPath>
      <g clip-path="url(#_clip1)">
        <g>
          <rect
            x="27.337"
            y="-0.141"
            width="11.295"
            height="28.362"
            style={{ fill: '#fcd34d', fillRule: 'nonzero' }}
          />
          <path
            d="M11.452,28.222l0,-28.363l-11.295,0l0,36.995l27.172,0l0,-8.632l-15.877,-0Z"
            style={{ fill: '#6ee7b7', fillRule: 'nonzero' }}
          />
        </g>
      </g>
    </svg>
  );
};
