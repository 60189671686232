import anime from 'animejs';
import { useRouter } from 'next/router';
import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import { MdClose, MdSearch } from 'react-icons/md';
import styled from 'styled-components';

const Wrapper = styled.form`
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 0;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
`;

const InputGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  color: ${({ theme }) => theme.colors.primary};
  transform: translateY(-100);
`;

const InputText = styled.input`
  width: 100%;
  padding: 1rem;
  font-size: 3rem;
  font-weight: 200;
  color: ${({ theme }) => theme.colors.primary};
  background: none;
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }
`;

const ButtonClose = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:focus {
    outline: none;
  }

  &:hover {
    transform: scale(1.2) rotate(10deg);
  }
`;

type SearchBarProps = {
  value: string;
  open: boolean;
  onChange: Dispatch<SetStateAction<string>>;
  onToggle: Dispatch<SetStateAction<boolean>>;
};

export const SearchBar = ({
  value,
  open,
  onChange,
  onToggle,
}: SearchBarProps) => {
  const router = useRouter();
  const wrapperRef = useRef<HTMLFormElement>(null);
  const groupRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    anime({
      targets: wrapperRef.current,
      height: open ? 106 : 0,
      duration: 300,
      delay: open ? 0 : 50,
      easing: 'easeInOutQuart',
    });
    anime({
      targets: groupRef.current,
      translateY: open ? 0 : -100,
      duration: 300,
      delay: open ? 50 : 0,
      easing: 'easeInOutQuart',
    });

    if (open) {
      inputRef.current?.focus();
    }
  }, [open]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { target } = event;
    onChange(target.value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    router.push(`/?q=${value}`);
  };

  return (
    <Wrapper ref={wrapperRef} onSubmit={handleSubmit}>
      <InputGroup ref={groupRef}>
        <MdSearch size={36} />
        <InputText
          ref={inputRef}
          placeholder="SEARCH HERE"
          value={value}
          onChange={handleChange}
        />
        <ButtonClose type="button" onClick={() => onToggle(false)}>
          <MdClose size={36} />
        </ButtonClose>
      </InputGroup>
    </Wrapper>
  );
};
