import anime from 'animejs';
import { signOut, useSession } from 'next-auth/react';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const Wrapper = styled.header`
  position: relative;
  width: 36px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

type ProfileProps = {
  readonly avatar?: string | null;
};

const Profile = styled.button<ProfileProps>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.white};
  outline: none;
  background-color: ${({ theme }) => theme.colors.white};
  background-image: url(${(props) => props.avatar});
  background-position: center center;
  background-repeat: none;
  background-size: cover;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:focus {
    outline: none;
  }

  &:hover {
    transform: scale(1.2) rotate(10deg);
  }
`;

const Me = styled.div`
  position: absolute;
  top: 20px;
  right: 70px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 1rem 0 0 0;
  color: ${({ theme }) => theme.colors.primary};
  overflow: hidden;

  p {
    margin: 0;
    padding: 0 1rem;
  }
`;

const Button = styled.button`
  width: 100%;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  margin: 1rem 0 0;
  padding: 0.5rem 1rem;
  font-weight: 600;
  background: ${({ theme }) => theme.colors.cherry};
  color: ${({ theme }) => theme.colors.white};

  &:focus {
    outline: none;
  }
`;

export const User = () => {
  const { data: session } = useSession();
  const [open, setOpen] = useState(false);
  const meRef = useRef<HTMLDivElement>(null);

  const handleLogout = (event: MouseEvent) => {
    event.preventDefault();
    signOut();
  };

  // useEffect(() => {
  //   if (!meRef.current) {
  //     return;
  //   }

  //   anime({
  //     targets: meRef.current,
  //     top: 10,
  //     opacity: [0, 1],
  //     duration: 300,
  //     delay: 50,
  //     easing: 'easeInOutQuart',
  //   });
  // }, [meRef]);

  return (
    <Wrapper>
      {session && (
        <Profile avatar={session?.user?.image} onClick={() => setOpen(!open)} />
      )}
      {open && (
        <Me ref={meRef} onMouseLeave={() => setOpen(false)}>
          <p>
            <strong>{session?.user?.name}</strong>
          </p>
          <p>{session?.user?.email}</p>
          <Button onClick={handleLogout}>Logout</Button>
        </Me>
      )}
    </Wrapper>
  );
};
