import anime from 'animejs';
import { useEffect, useRef } from 'react';
import { RiLoader4Fill } from 'react-icons/ri';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 36px;
  height: 36px;
  margin: 1rem;
  transform-origin: center;
`;

export const Loading = () => {
  const iconRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    anime({
      targets: iconRef.current,
      rotate: [0, 359],
      duration: 900,
      easing: 'linear',
      loop: true,
    });
  }, [iconRef]);

  return (
    <Wrapper ref={iconRef}>
      <RiLoader4Fill size={36} />
    </Wrapper>
  );
};
