import Link from 'next/link';
import toast from 'react-hot-toast';
import { BiCopy, BiLink } from 'react-icons/bi';
import { GiPirateFlag } from 'react-icons/gi';
import { SiGitbook } from 'react-icons/si';
import styled from 'styled-components';
import type { CardType } from 'types';

const ColorMap = (type?: string) => {
  switch (type) {
    case 'WTF':
      return 'aqua';
    case 'GitBook':
      return 'cherry';
    case 'Search':
      return 'lemon';
    default:
      return 'white';
  }
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.primaryLight};

  @media all and (min-width: 726px) {
    flex-direction: row;
  }
`;

const CardContent = styled.div`
  align-self: flex-start;
  flex-grow: 1;
  width: 100%;
  padding: 1rem;
`;

const CardImage = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 126px;
  height: 126px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: center;
  padding: 10px;

  @media all (min-width: 1024px) {
    height: 100%;
  }
`;

const CardTitle = styled.h3`
  font-size: 2rem;
  font-weight: 200;
  margin: 0 0 5px 0;
`;

const CardDescription = styled.p`
  font-size: 1rem;
  margin: 3px 0 20px;
  font-weight: 200;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const CardLink = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  margin: 3px 0;
  font-weight: 200;
  color: ${({ theme }) => theme.colors.white};

  a:hover {
    text-decoration: underline;
  }

  svg {
    flex-shrink: 0;
    margin-left: 10px;
    cursor: pointer;

    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.2) rotate(10deg);
    }
  }
`;

const TagWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const CardTag = styled.span<{ type?: string }>`
  background: ${({ type, theme }) => theme.colors[ColorMap(type)]};
  padding: 1px 10px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.primary};
`;

const CardTagLink = styled(CardTag)`
  cursor: pointer;
`;

export const Card = ({
  title,
  description,
  url,
  short,
  thumbnail,
  source = 'WTF',
  favicon,
  tags,
}: Partial<CardType>) => {
  const handleCopy = (content: string) => {
    navigator.clipboard.writeText(content);
    toast.success('Awesome link copied!');
  };

  return (
    <Wrapper>
      <CardImage style={{ backgroundImage: `url(${thumbnail})` }}>
        {source === 'GitBook' && <SiGitbook size={56} />}
        {source === 'Search' && <GiPirateFlag size={56} />}
        {source === 'WTF' && !thumbnail && <BiLink size={56} />}
      </CardImage>
      <CardContent>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description || 'No description'}</CardDescription>
        {url && (
          <CardLink>
            <a href={url} target="__blank">
              {url.slice(0, 50)}
              {url.length > 50 && '...'}
            </a>
            <BiCopy onClick={() => handleCopy(url)} size={20} />
          </CardLink>
        )}
        {short && (
          <CardLink>
            <a href={`/${short}`} target="__blank">
              {short}
            </a>
            <BiCopy onClick={() => handleCopy(short)} size={20} />
          </CardLink>
        )}
        <TagWrapper>
          <CardTag type={source}>{source}</CardTag>
          {tags?.map((tag) => (
            <Link key={tag} href={`/tags/${tag}`}>
              <CardTagLink>{tag}</CardTagLink>
            </Link>
          ))}
        </TagWrapper>
      </CardContent>
    </Wrapper>
  );
};
