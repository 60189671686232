import Image from 'next/image';
import styled from 'styled-components';
import { CardType } from 'types';
import { Card } from './Card';

type CardListProps = {
  data: CardType[];
};

const Wrapper = styled.div`
  width: 100%;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @media all and (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const CardList = ({ data }: CardListProps) => {
  return (
    <Wrapper>
      <>
        {data.length === 0 && (
          <Image
            src="/images/no-found.jpg"
            layout="responsive"
            width="1280"
            height="668"
            alt="Search not found"
          />
        )}
        {data.map((item) => (
          <Card key={item.id} {...item} />
        ))}
      </>
    </Wrapper>
  );
};
